<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <transaction-from-box
        list-url="/complain"
        :id="id"
        checklist-type="complain"
        :form-type="formType"
        @add="onAdd"
        @edit="onEdit"
        :loading="loading"
      >
      </transaction-from-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import TransactionFromBox from '../../components/TransactionFromBox.vue';

export default {
  name: 'ComplainForm',
  components: {
    TransactionFromBox
  },
  async mounted() {
    if (this.$route.name === 'transaction-complain-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'complain',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Complain' : 'Update Complain';
    },
    ...mapState('transactionComplain', ['loading', 'baseUrl', 'pagination', 'complainList', 'imageUrl']),
    ...mapState('auth', ['user']),
    ...mapState('cstSubmission', ['store'])
  },
  methods: {
    ...mapActions('transactionComplain', ['getOne', 'postOne', 'patchOne', 'getImageUrl']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    onAdd({ complain }) {
      this.postOne({
        complain,
        router,
        redirectUrl: '/transaction-complain'
      });
    },
    onEdit({ complain }) {
      this.patchOne({
        type: 'complain',
        id: this.id,
        complain,
        router,
        redirectUrl: '/transaction-complain'
      });
      if (complain.images && complain.images.length > 0) {
        this.imagePreview = complain.images;
      }
    }
  }
};
</script>
