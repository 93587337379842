<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <TicketBox
      :columns="columns"
      :rows="tickets"
      :baseUrl="baseUrl"
      :loading="loading"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      :showSearch="true"
      :showFilter="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      @edit="onEdit"
      emptyText="No Data Found."
    ></TicketBox>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import TicketBox from '../../components/TicketBox.vue';

export default {
  components: { TicketBox },
  name: 'TicketList',
  metaInfo() {
    return {
      title: 'Tickets',
      meta: [
        {
          name: 'tickets',
          content: `Download mis-tickets`
        }
      ]
    };
  },
  data() {
    return {
      title: 'Tickets',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Email',
          class: { 'text-center': true },
          textKey: 'email',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Issue',
          class: { 'text-center': true },
          textKey: 'issue',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Details',
          class: { 'text-center': true },
          textKey: 'details',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Complaint No.',
          class: { 'text-center': true },
          textKey: 'complainNumber',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Attachment',
          class: { 'text-center': true },
          urlKey: 'imageUrl',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Resolved',
          class: { 'text-center': true },
          textKey: 'isResolved',
          width: '10%'
        },

        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '9%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '8%',
          functions: {
            edit: true,
            delete: false
          }
        }
      ],
      searchItems: ['Complain Number', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Resolved-True', text: 'Resolved-True' },
        { value: 'Resolved-False', text: 'Resolved-False' },
        { value: 'Status-PENDING', text: 'Status-Pending' },
        { value: 'Status-ACKNOWLEDGED', text: 'Status-Acknowledged' },
        { value: 'Status-IN PROGRESS', text: 'Status-In Progress' },
        { value: 'Status-WAITING FOR USER RESPONSE', text: 'Status-Waiting For User Response' }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listTickets({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listTickets({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listTickets({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('contactUs', ['listTickets']),
    onEdit({ row }) {
      Vue.swal({
        title: 'Ticket Details',
        html: `<div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="name">Ticket Current Status</label>
            <input type="text" class="form-control" id="name" value="${row.currentStatus}" disabled>
          </div>
          <div class="form-group">
            <label for="mobile">Estimated Time</label>
            <input type="text" class="form-control" id="mobile" value="${row.escalationTime}" disabled>
          </div>`,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark',
          confirmButton: 'btn btn-primary',
          popup: 'col-md-3 animate__animated animate__fadeInDown',
          input: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      });
    }
  },
  computed: {
    ...mapState('contactUs', ['loading', 'baseUrl', 'pagination', 'tickets']),
    ...mapState('darkMode', ['isDarkMode'])
  }
};
</script>

<style></style>
