<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <silver-coin-box
      :columns="columns"
      :rows="tickets"
      :baseUrl="baseUrl"
      :loading="loading"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      :showSearch="true"
      :showFilter="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      @edit="onEdit"
      emptyText="No Data Found."
    >
    </silver-coin-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import SilverCoinBox from '../../components/SilverCoinBox.vue';

export default {
  components: { SilverCoinBox },
  name: 'SilverCoinList',
  metaInfo() {
    return {
      title: 'Tickets',
      meta: [
        {
          name: 'tickets',
          content: `Download mis-tickets`
        }
      ]
    };
  },
  data() {
    return {
      title: 'Support Tickets',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Email',
          class: { 'text-center': true },
          textKey: 'email',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Issue',
          class: { 'text-center': true },
          textKey: 'issue',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Details',
          class: { 'text-center': true },
          textKey: 'details',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Complaint No.',
          class: { 'text-center': true },
          textKey: 'complainNumber',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Attachment',
          class: { 'text-center': true },
          urlKey: 'imageUrl',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Role',
          class: { 'text-center': true },
          textKey: 'submittedByRole',
          width: '10%'
        },

        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '9%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '8%',
          functions: {
            edit: true,
            delete: false
          }
        }
      ],
      searchItems: ['Complain Number', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Resolved-True', text: 'Resolved-True' },
        { value: 'Resolved-False', text: 'Resolved-False' },
        { value: 'Status-PENDING', text: 'Status-Pending' },
        { value: 'Status-ACKNOWLEDGED', text: 'Status-Acknowledged' },
        { value: 'Status-IN PROGRESS', text: 'Status-In Progress' },
        { value: 'Status-WAITING FOR USER RESPONSE', text: 'Status-Waiting For User Response' },
        { value: 'Source-APP', text: 'Source-App' },
        { value: 'Source-WEB', text: 'Source-Web' }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listTickets({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listTickets({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listTickets({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('contactUs', ['listTickets']),
    onEdit({ row }) {
      router.push(`/complain/${row.id}`);
    }
  },
  computed: {
    ...mapState('contactUs', ['loading', 'baseUrl', 'pagination', 'tickets'])
  }
};
</script>

<style></style>
