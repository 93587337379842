<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>

<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-row>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Transaction Type
                <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="form.transactionType"
                :disabled="isUpdateForm"
                placeholder="Select Store Ids"
                label="text"
                :options="issueType"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </b-form-select>
              <b-form-invalid-feedback id="input-store-invalid">Please select a Issue type.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-description" label-for="input-description">
              <template v-slot:label>
                Customer Mobile
                <span class="text-danger">*</span>
              </template>

              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-description"
                type="number"
                v-model="form.customerPhone"
                placeholder="Enter Mobile Number"
                :disabled="isUpdateForm"
                :state="$v.form.customerPhone.$dirty ? !$v.form.customerPhone.$error : null"
                @input="validateCustomerPhone"
                maxlength="10"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              ></b-form-input>

              <b-form-invalid-feedback id="input-name-invalid"
                >Customer Mobile number must be 10 digit long.</b-form-invalid-feedback
              >
            </b-form-group></b-col
          >
        </b-row>
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-title" label-for="input-title">
              <template v-slot:label>
                Customer Name
                <span class="text-danger">*</span>
              </template>

              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-title"
                type="text"
                v-model="form.customerName"
                placeholder="Enter Customer Name"
                :disabled="isUpdateForm"
                :state="$v.form.customerName.$dirty ? !$v.form.customerName.$error : null"
              ></b-form-input>
              <b-form-invalid-feedback id="input-name-invalid">Please enter Customer Name.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Store Ids
                <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="form.storeids"
                :disabled="isUpdateForm"
                placeholder="Select Store Ids"
                label="text"
                :options="stores"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </b-form-select>
              <b-form-invalid-feedback id="input-store-invalid">Please select a Store Id.</b-form-invalid-feedback>
            </b-form-group></b-col
          >
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Transaction ID
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-description"
                type="text"
                v-model="form.transactionId"
                placeholder="Enter Transaction ID"
                :disabled="isUpdateForm"
                :state="$v.form.transactionId.$dirty ? !$v.form.transactionId.$error : null"
              ></b-form-input>
              <b-form-invalid-feedback id="input-name-invalid">Please enter Transaction ID.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Transaction amount
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-description"
                type="number"
                v-model="form.transactionAmount"
                :disabled="isUpdateForm"
                placeholder="Enter Transaction amount"
                :state="$v.form.transactionAmount.$dirty ? !$v.form.transactionAmount.$error : null"
              ></b-form-input>
              <b-form-invalid-feedback id="input-name-invalid"
                >Please enter Transaction amount.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" lg="6">
            <b-form-group id="group-description" label-for="input-description">
              <template v-slot:label>
                Helpline Number
                <span class="text-danger">*</span>
              </template>

              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-description"
                type="text"
                v-model="form.smMobile"
                placeholder="Enter Helpline Number(comma-separated)"
                :disabled="isUpdateForm"
                :state="$v.form.smMobile.$dirty ? !$v.form.smMobile.$error : null"
                @input="validateSMMobile"
              ></b-form-input>

              <b-form-invalid-feedback id="input-name-invalid">
                Please enter valid 10-digit mobile numbers separated by commas.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row> </b-row>

        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-remark" label-for="input-remark">
              <template v-slot:label>
                Customer Remark
                <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-remark"
                v-model="form.customerRemarks"
                :disabled="isUpdateForm"
                rows="5"
                placeholder="Enter Remarks"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col v-if="!ifStoreManager() || ifAdmin() || form.approverRemarks">
            <b-form-group id="group-remark" label-for="input-remark">
              <template v-slot:label>
                In Progress Remarks
                <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-remark"
                rows="5"
                v-model="form.inProgressRemarks"
                :disabled="isDisable"
                placeholder="Enter Progress Remarks"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-remark-invalid">
                Remark is required when updating.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="!ifStoreManager() || ifAdmin() || form.approverRemarks">
          <b-col sm="12" lg="6">
            <b-form-group id="group-remark" label-for="input-remark">
              <template v-slot:label>
                Approver Remark
                <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-remark"
                rows="5"
                v-model="form.approverRemarks"
                placeholder="Enter Remarks"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-remark-invalid">
                Remark is required when updating.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col> </b-col>
        </b-row>
        <div v-viewer>
          <b-form-group id="group-products" label-for="input-products">
            <template v-slot:label>
              {{ isUpdateForm ? 'Uploaded Image' : 'Upload Image' }}
              <span class="text-danger">*</span>
            </template>
            <input v-if="!isUpdateForm" type="file" @change="uploadFile($event)" accept=".jpg, .jpeg, .png" />
            <div v-if="imagePreview && imagePreview.length > 0" class="mt-2 imageView">
              <img
                v-for="(imgSrc, index) in imagePreview"
                :key="index"
                :src="imgSrc"
                alt="Preview Image"
                class="mx-2"
                style="width: 120px; height: 120px"
              />
              <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
            </div>
          </b-form-group>
        </div>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button
                v-if="ifFinanceAdmin() && this.currentStatus === 'CREATED'"
                class="box-shadow2 mr-2"
                type="button"
                size="sm"
                :variant="isDarkMode ? 'outline-primary' : 'primary'"
                :disabled="loading"
                @click="saveDraft"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save as Draft
              </b-button>

              <b-button
                v-if="handleButtonDisable()"
                class="box-shadow2"
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />{{
                  ifFinanceAdmin() ? 'Final Submit' : 'Submit'
                }}
              </b-button>

              <b-button
                class="ml-2 box-shadow2"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/transaction-complain` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapGetters, mapState, mapActions } from 'vuex';
import configService from '@/services/configService';
import router from '@/router';
import Multiselect from 'vue-multiselect';
import VueViewer from 'v-viewer';
import Vue from 'vue';

Vue.use(VueViewer);
export default {
  name: 'TransactionFrom',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    documentType: String,
    loading: Boolean
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          name: ''
        }
      ],

      formLoaded: true,
      storeids: '',
      stores: [],
      complaintNumber: '',
      issueType: [
        { value: null, text: 'Select Filter' },
        { value: 'CARD', text: 'Card' },
        { value: 'UPI', text: 'UPI Payment' }
      ],
      currentStatus: '',
      form: {
        customerName: null,
        customerPhone: null,
        smMobile: '',
        transactionId: null,
        transactionAmount: null,
        selectDate: moment().format('DD-MM-YYYY'),
        storeids: '',
        transactionType: null,
        currentStatus: '',
        customerRemarks: null,
        approverRemarks: null,
        inProgressRemarks: null,
        file: [],
        fileName: []
      },
      minDate: moment().format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      dateTimeFormat: configService.get('format').pickerDateTime,
      selectDate: moment().format('DD-MM-YYYY'),
      imagePreview: null
    };
  },
  validations() {
    const formValidation = {
      customerName: {
        required
      },
      customerPhone: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10)
      },
      smMobile: {
        required,
        validateMobileNumbers(value) {
          if (!value) return false;
          const numbers = value.split(',').map(num => num.trim());
          return numbers.every(num => /^\d{10}$/.test(num));
        }
      },

      transactionId: {
        required
      },
      transactionAmount: {
        required
      },
      selectDate: {
        required
      }
      // approverRemarks: {
      //   required: this.formType === 'update'
      // }
    };

    if (this.formType === 'new') {
      formValidation.customerName.required = required;
      formValidation.customerPhone.required = required;
      formValidation.smMobile.required = required;
      formValidation.transactionId.required = required;
      formValidation.transactionAmount.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.getStoreByUser({ router });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch();
        this.$v.$reset();
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Transaction Complain' : 'Update Transaction Complain';
    },
    isUpdateForm() {
      return this.formType === 'update';
    },
    isDisable() {
      return this.currentStatus === 'IN PROGRESS';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('auth', ['user']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('transactionComplain', ['baseUrl', 'pagination', 'complainList', 'complain', 'images'])
  },
  methods: {
    ...mapActions('transactionComplain', ['getOne', 'postOne', 'patchOne', 'uploadImage']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    handleButtonDisable() {
      //for store STORE_SALES_MANAGER
      if (this.formType === 'new' && this.ifStoreManager()) {
        return true;
      } else if (this.ifFinanceAdmin() && (this.currentStatus === 'IN PROGRESS' || this.currentStatus === 'CREATED')) {
        return true;
      }
      return false;
    },
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN');
      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    ifStoreManager() {
      const isStoreManager = this.user.roles.filter(role => role.name === 'STORE_SALES_MANAGER');
      if (isStoreManager.length) {
        return true;
      }
      return false;
    },
    ifFinanceAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'FINANCE_ADMIN');
      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    validateSMMobile(value) {
      if (!value) return;

      let numbers = value.split(',').map(num => num.trim());

      numbers = numbers.map(num => {
        num = num.replace(/\D/g, '');
        return num.slice(0, 10);
      });
      this.form.smMobile = numbers.join(', ');
    },
    validateCustomerPhone(value) {
      if (value && value.toString().length > 10) {
        this.form.customerPhone = value.toString().slice(0, 10);
      }
    },
    uploadFile(event) {
      let allAreImages = true;
      this.form.file = [];

      if (event.target.files && event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          if (!file || !file.type.includes('image')) {
            allAreImages = false;
            event.target.value = '';
            alert('Only image files are allowed.');
            break;
          }
        }

        if (allAreImages) {
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            if (file) {
              this.form.file.push(file);

              const reader = new FileReader();
              reader.onload = e => {
                if (!this.imagePreview) {
                  this.imagePreview = [];
                }
                this.imagePreview.push(e.target.result);
              };
              reader.readAsDataURL(file);
            }
          }
          this.form.file.forEach(file => {
            if (file) {
              this.uploadImage({ file }, {}, router);
            }
          });
        }
      }
    },

    saveDraft() {
      const complain = {
        customerName: this.form.customerName,
        customerPhone: this.form.customerPhone,
        smMobile: this.form.smMobile
          .split(',')
          .map(num => num.trim())
          .join(', '),
        transactionId: this.form.transactionId,
        transactionAmount: this.form.transactionAmount,
        transactionDate: this.form.selectDate,
        storeId: this.form.storeids,
        transactionType: this.form.transactionType,
        customerRemarks: this.form.customerRemarks,
        approverRemarks: this.form.approverRemarks,
        inProgressRemarks: this.form.inProgressRemarks,
        images: this.file ? this.file : [],
        currentStatus: 'IN PROGRESS',
        complaintNumber: this.complaintNumber
      };

      this.$emit('edit', { complain });
    },

    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const complain = {
        customerName: this.form.customerName,
        customerPhone: this.form.customerPhone,
        smMobile: this.form.smMobile
          .split(',')
          .map(num => num.trim())
          .join(', '),
        transactionId: this.form.transactionId,
        transactionAmount: this.form.transactionAmount,
        transactionDate: this.form.selectDate,
        storeId: this.form.storeids,
        transactionType: this.form.transactionType,
        customerRemarks: this.form.customerRemarks,
        approverRemarks: this.form.approverRemarks,
        inProgressRemarks: this.form.inProgressRemarks,
        complaintNumber: this.complaintNumber,
        currentStatus: this.ifFinanceAdmin() ? 'CLOSED' : 'CREATED',
        images: this.file ? this.file : []
      };
      if (this.formType === 'new') {
        this.$emit('add', { complain });
      } else {
        this.$emit('edit', { complain });
     }
      return false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    complain(newValue) {
      if (newValue.id !== '') {
        this.currentStatus = newValue.currentStatus;
        this.form.customerName = newValue.customerName;
        this.form.customerPhone = newValue.customerPhone;
        this.form.smMobile = newValue.smMobile;
        this.form.transactionId = newValue.transactionId;
        this.form.transactionAmount = newValue.transactionAmount;
        this.form.customerRemarks = newValue.customerRemarks;
        this.form.storeids = newValue.storeId;
        this.form.transactionType = newValue.transactionType;
        this.form.approverRemarks = newValue.approverRemarks;
        this.form.inProgressRemarks = newValue.inProgressRemarks;
        this.form.selectDate = newValue.transactionDate;
        this.complaintNumber = newValue.complaintNumber;
        if (newValue.images && newValue.images.length > 0) {
          this.file = newValue.images;
          this.imagePreview = newValue.images.map(image => image);
        }
      }
    },
    store(newValue) {
      this.stores = [
        { text: 'Select Store ID', value: '' },
        ...newValue.map(store => ({
          text: store.storeid,
          value: store.storeid
        }))
      ];

      if (this.storeids) {
        const matchingStore = this.stores.find(store => store.value === this.storeids.value);
        if (matchingStore) {
          this.storeids = matchingStore;
        } else {
          this.storeids = { text: 'Select Store ID', value: '' };
        }
      }
    },

    images(newValue) {
      if (newValue !== null && newValue.length > 0) {
        this.file = newValue;
      }
    },
    user(newValue) {}
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}

.date {
  margin-left: -10px;
}

.upload {
  margin-top: 8px;
}

.videoPlayer {
  width: 25%;
}
</style>
<style scoped>
.textarea-height {
  height: 40px;
  /* Set your desired height here */
}
.imageView {
  display: inline-flex;
  margin: 2px;
}
</style>
