var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"page-title text-center"},[_vm._v("Update Ticket")]),(_vm.loading || !_vm.formLoaded)?_c('div',[_c('span',{staticClass:"spinner"})]):_vm._e(),(!_vm.loading && _vm.formLoaded)?_c('div',[_c('b-form',{class:{
        'p-3 box-shadow2 rounded': !_vm.isDarkMode,
        'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
      },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Resolved "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3969679315)},[_c('multiselect',{class:{
                'box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"id":"input-status","placeholder":"Select status","options":['True', 'False']},model:{value:(_vm.resolved),callback:function ($$v) {_vm.resolved=$$v},expression:"resolved"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Current Status "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3002842814)},[_c('multiselect',{class:{
                'box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"id":"input-status","placeholder":"Select status","options":['PENDING', 'ACKNOWLEDGED', 'IN PROGRESS', 'WAITING FOR USER RESPONSE', 'RESOLVED']},model:{value:(_vm.currentStatus),callback:function ($$v) {_vm.currentStatus=$$v},expression:"currentStatus"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Complaint Source "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,1762919025)},[_c('b-form-input',{class:{
                'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-complaint-source","type":"text","disabled":"","placeholder":"Enter complaint source"},model:{value:(_vm.complaintSource),callback:function ($$v) {_vm.complaintSource=$$v},expression:"complaintSource"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Issue Priority "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,4039416776)},[_c('multiselect',{class:{
                'box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"id":"input-status","placeholder":"Select Priority","disabled":"","options":['LOW', 'MEDIUM', 'HIGH']},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Estimated Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3962052516)},[_c('b-form-input',{class:{
                'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-escalation-time","type":"text","placeholder":"Enter estimated time"},model:{value:(_vm.escalationTime),callback:function ($$v) {_vm.escalationTime=$$v},expression:"escalationTime"}})],1)],1)],1),_c('b-form-group',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success',"disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/complain" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }