var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-tablebox"},[(_vm.showToolbar)?_c('b-row',{staticClass:"table-top-wrapper mb-2 mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"sm":""}},[_c('b-form',{attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.showSearch)?_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2",attrs:{"data-v-step":"0"},scopedSlots:_vm._u([(_vm.searchItems)?{key:"append",fn:function(){return [_c('b-dropdown',{attrs:{"menu-class":_vm.isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2',"variant":_vm.isDarkMode ? 'primary' : 'success',"text":_vm.selectedSearchVariable.text}},_vm._l((_vm.searchItems),function(item,index){return _c('b-dropdown-item',{key:index,on:{"click":function($event){_vm.selectedSearchVariable = item;
                  _vm.searchTerm = '';}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)]},proxy:true}:null],null,true)},[_c('b-form-input',{class:{
              'bg-light text-dark': !_vm.isDarkMode,
              'bg-dark text-light placeholder-light': _vm.isDarkMode
            },attrs:{"placeholder":"Search..."},nativeOn:{"keydown":function($event){return _vm.search.apply(null, arguments)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(_vm.showFilter)?_c('div',{attrs:{"data-v-step":"1"}},[_c('b-form-select',{class:{
              'box-shadow2 ml-2 mb-2 bg-light text-dark': !_vm.isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': _vm.isDarkMode
            },attrs:{"options":_vm.filterOptions},model:{value:(_vm.filterSelected),callback:function ($$v) {_vm.filterSelected=$$v},expression:"filterSelected"}})],1):_vm._e()],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"sm":""}},[_c('b-form',{staticClass:"float-right",attrs:{"data-v-step":"2","inline":""}},[_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","id":"start-datepicker","placeholder":"Start Date","min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.startDateValue),callback:function ($$v) {_vm.startDateValue=$$v},expression:"startDateValue"}})],1),_c('b-form-input',{class:{
              '': !_vm.isDarkMode,
              'bg-dark text-light placeholder-light': _vm.isDarkMode
            },attrs:{"id":"start-date-input","type":"text","placeholder":"Start Date","autocomplete":"off","disabled":""},model:{value:(_vm.startDateValue),callback:function ($$v) {_vm.startDateValue=$$v},expression:"startDateValue"}})],1),_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","id":"end-datepicker","placeholder":"End Date","min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.endDateValue),callback:function ($$v) {_vm.endDateValue=$$v},expression:"endDateValue"}})],1),_c('b-form-input',{class:{
              '': !_vm.isDarkMode,
              'bg-dark text-light placeholder-light': _vm.isDarkMode
            },attrs:{"id":"end-date-input","type":"text","placeholder":"End Date","autocomplete":"off","disabled":""},model:{value:(_vm.endDateValue),callback:function ($$v) {_vm.endDateValue=$$v},expression:"endDateValue"}})],1),_c('b-button',{class:{
            'box-shadow2 btn btn-success ml-2 mb-2': !_vm.isDarkMode,
            'btn btn-primary text-light ml-2 mb-2': _vm.isDarkMode
          },on:{"click":_vm.applyDate}},[_vm._v("Apply")]),_c('b-button',{staticClass:"box-shadow2 ml-2 mb-2",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'outline-secondary'},on:{"click":_vm.clearDate}},[_vm._v("Clear")])],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"table-bottom-wrapper mb-2 mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"cols":12}},[(_vm.showAdd)?_c('b-button',{class:_vm.buttonClasses,attrs:{"data-v-step":"3","size":"sm"},on:{"click":_vm.clickAdd}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.addText)+" ")],1):_vm._e(),(_vm.showForm && _vm.ifAdmin())?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickOpenForm}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.formButtonText)+" ")],1):_vm._e(),(_vm.showDownload)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadText)+" ")],1):_vm._e(),(_vm.showDeletedDownload && !_vm.ifAdmin())?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownloadDeleteList}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadDeleteText)+" ")],1):_vm._e(),(_vm.showUpload)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"5","size":"sm"},on:{"click":_vm.clickUpload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'upload']}}),_vm._v(_vm._s(_vm.uploadText)+" ")],1):_vm._e(),(_vm.showList)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickList}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'eye']}}),_vm._v(_vm._s(_vm.listText)+" ")],1):_vm._e()],1)],1),_c('div',{staticClass:"table-top-wrapper"},[(_vm.totalCount)?_c('div',{staticClass:"row-total-count"},[_c('span',[_vm._v("Total Count:")]),_vm._v(" "+_vm._s(_vm.totalCount)+" ")]):_vm._e()]),_c('div',{class:{
      'box-shadow2': !_vm.isDarkMode,
      'min-height-522': _vm.isDarkMode
    }},[_c('div',{class:{
        'table-row table-row-header v-step-1': !_vm.isDarkMode,
        'table-row-dark v-step-1 dark-div border-top': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,class:Object.assign({}, column.class,
          {'table-column': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[_vm._v(" "+_vm._s(column.headerText)+" ")])}),0),_vm._l((_vm.rows),function(row){return _c('div',{key:row.id,class:{
        'table-row ': !_vm.isDarkMode,
        'table-row-dark': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,class:Object.assign({}, column.class,
          {'table-column ': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[(column.slotKey)?_vm._t(column.slotKey,null,{"row":row}):_vm._e(),(column.htmlKey)?_c('div',{domProps:{"innerHTML":_vm._s(row[column.htmlKey])}}):_vm._e(),(column.urlKey)?_c('div',[_c('a',{attrs:{"href":row[column.urlKey],"target":"_blank"}},[_vm._v(_vm._s(row[column.urlKey]))])]):_vm._e(),(column.textKey && column.textKey !== 'images')?_c('span',{staticClass:"span-text"},[_vm._v(_vm._s(row[column.textKey]))]):_vm._e(),(column.type === 'image' && typeof row[column.textKey] !== 'string' && row[column.textKey])?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}]},[_c('div',{staticClass:"image-container"},[[(row[column.textKey].length > 0)?_c('div',{staticClass:"imageView"},[_c('img',{staticClass:"dynamic-image box-shadow2",attrs:{"src":row[column.textKey][0],"thumbnail":"","fluid":"","alt":"No Image Available"}})]):_vm._e(),(row[column.textKey].length > 1)?_c('div',{staticClass:"more-images"},[_vm._v(" +"+_vm._s(row[column.textKey].length - 1)+" more ")]):_vm._e()]],2)]):_vm._e(),_c('link',{attrs:{"href":"//unpkg.com/viewerjs/dist/viewer.css","rel":"stylesheet"}}),(column.type === 'functions')?_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"button-fixed-width",attrs:{"data-v-step":"7","size":"sm","variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":function($event){return _vm.clickEdit(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_c('span',[_vm._v("View/Edit")])],1),(_vm.ifAdmin() && row.currentStatus !== 'IN PROGRESS' && row.currentStatus !== 'CLOSED')?_c('b-button',{staticClass:"mb-2 button-fixed-width",class:{
              'box-shadow2': !_vm.isDarkMode,
              'text-white': _vm.isDarkMode
            },attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning'},on:{"click":function($event){return _vm.clickDelete(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'trash-alt']}}),_c('span',[_vm._v("Delete")])],1):_vm._e()],1):_vm._e()],2)}),0)}),(!_vm.loading && _vm.rows.length === 0)?_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
          'table-column table-column-full text-center': !_vm.isDarkMode,
          'table-column-dark table-column-full text-center': _vm.isDarkMode
        }},[_vm._v(_vm._s(_vm.emptyText))])]):_vm._e(),(_vm.loading)?_c('b-overlay',{attrs:{"show":"","variant":_vm.isDarkMode ? 'outline-light' : 'outline-secondary'}},[_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
            'table-column table-column-full text-center': !_vm.isDarkMode,
            'table-column-dark table-column-full text-center': _vm.isDarkMode
          }})])]):_vm._e()],2),_c('b-row',{staticClass:"table-bottom-wrapper mt-2 mx-0"},[_c('b-col',{attrs:{"lg":"8","sm":"12"}},[(_vm.showAdd)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickAdd}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.addText)+" ")],1):_vm._e(),(_vm.showUpload)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickUpload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'upload']}}),_vm._v(_vm._s(_vm.uploadText)+" ")],1):_vm._e(),(_vm.showList)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickList}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'eye']}}),_vm._v(_vm._s(_vm.listText)+" ")],1):_vm._e()],1),(_vm.showPagination)?_c('b-col',{attrs:{"lg":"4","sm":"12"}},[(_vm.rows.length)?_c('b-pagination-nav',{class:{ '': !_vm.isDarkMode, 'dark-nav': _vm.isDarkMode },attrs:{"data-v-step":"6","link-gen":_vm.linkGen,"number-of-pages":_vm.totalNumberOfPage,"use-router":"","no-page-detect":"","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }